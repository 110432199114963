<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('平台告警信息')}}</span>
        <div>
          <el-button type="primary" size="small" v-if="user_role['platform/get_config']" @click="getDispose">{{$t('查看配置信息')}}</el-button>
          <el-button type="primary" size="small" v-if="user_role['platform/get_platform_info']" @click="ispull = true">{{$t('拉取平台信息')}}</el-button>
          <el-button type="primary" size="small" @click="getUserList(1,1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <el-row :gutter="20" class="searchBar">
        <el-col :span="6">
          <el-select v-model="ocID" clearable :placeholder="$t('请选择客户')" @change="getEID(ocID)">
            <el-option v-for="item in ocList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-date-picker v-model="STime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="-"
            :start-placeholder="$t('开始日期')" :end-placeholder="$t('结束日期')">
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-select v-model="sEquipment" clearable :placeholder="$t('请选择设备')">
            <el-option v-for="item in equipment" :key="item.id" :label="item.equipment_id" :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-col>
      </el-row>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('ID')" prop="id" fixed width="100">
        </el-table-column>
        <el-table-column :label="$t('客户名称')" prop="kehu_name" width="200">
        </el-table-column>
        <el-table-column :label="$t('警告信息')" prop="warning_info" width="200">
        </el-table-column>
        <el-table-column :label="$t('设备ID')" prop="equipment_id" width="150">
        </el-table-column>
        <el-table-column :label="$t('警告码')" prop="warning_code" width="150">
        </el-table-column>
        <el-table-column :label="$t('告警次数')" prop="frequency" width="100">
        </el-table-column>
        <el-table-column :label="$t('站点')" prop="rname" width="300">
        </el-table-column>
        <el-table-column :label="$t('备注信息')" prop="info" width="200">
        </el-table-column>
        <el-table-column :label="$t('最近告警时间')" prop="update_time" width="200">
        </el-table-column>
        <el-table-column :label="$t('操作')" fixed="right" align="center" header-align="left" width="150">
          <template v-slot="scope">
             <el-button type="text" @click="getAddorder(scope)" v-if="user_role['work_order/add']">{{$t('生成工单')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <!-- 配置信息 -->
    <el-dialog :title="$t('平台配置信息')" :visible.sync="dispose" width="80%">
      <el-form :model="disposeForm" :label-position="getLable" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('本平台key')">
              <el-input v-model="disposeForm.appkey" :placeholder="$t('请填写')" :disabled="isedit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('本平台url')">
              <el-input v-model="disposeForm.url" :placeholder="$t('请填写')" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('第三方key')">
              <el-input v-model="disposeForm.platform_appkey" :placeholder="$t('请填写')" :disabled="isedit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('第三方url')">
              <el-input v-model="disposeForm.platform_url" :placeholder="$t('请填写')" :disabled="isedit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dispose = false">{{$t('取消')}}</el-button>
        <el-button @click="isedit = false" v-if="isedit&&user_role['platform/set_config']">{{$t('修改')}}</el-button>
        <el-button type="warning" @click="editDis" v-else-if="!isedit&&user_role['platform/set_config']">{{$t('保存')}}</el-button>
      </span>
    </el-dialog>
    <!-- 拉取平台数据 -->
    <el-dialog :title="$t('拉取平台信息')" :visible.sync="ispull" width="30%">
      <el-form :model="pullForm" :label-position="getLable" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('数据类型')">
              <el-select v-model="pullForm.customer" :placeholder="$t('请选择')">
                <el-option v-for="item in CList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ispull = false">{{$t('取消')}}</el-button>
        <el-button type="warning" @click="pullInfo">{{$t('获取')}}</el-button>
      </span>
    </el-dialog>
    <!-- 生成工单弹窗可以用自定义组件的方式优化，此功能在多个地方都使用到了 -->
    <el-dialog :title="$t('生成工单')" :visible.sync="addOFM" width="80%">
      <el-row :gutter="20">
        <el-form :model="addOrder" :label-position="getLable" label-width="100px" ref="addOrder">
          <!-- <el-col :span="12">
            <el-form-item label="预约记录ID" prop="os_id">
              <el-input v-model="addOrder.os_id" placeholder="请输入预约ID" clearable></el-input>
            </el-form-item>
          </el-col> --><!-- /预约记录ID -->
          <el-col :span="12">
            <el-form-item :label="$t('客户名称')" required prop="oc_id"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addOrder.oc_id" @change="getInfoID(addOrder.oc_id)" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in ocList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /客户ID -->
          <el-col :span="12">
            <el-form-item :label="$t('服务类型')" required prop="service_type"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addOrder.service_type" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in serList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /服务类型 -->
          <el-col :span="12">
            <el-form-item :label="$t('联系人')" required prop="contacts"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addOrder.contacts" clearable></el-input>
            </el-form-item>
          </el-col><!-- /联系人 -->
          <el-col :span="12">
            <el-form-item :label="$t('联系电话')" required prop="contact_number"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addOrder.contact_number" clearable></el-input>
            </el-form-item>
          </el-col><!-- /区域联系电话 -->
          <el-col :span="12">
            <el-form-item :label="$t('服务内容')" required prop="service_content"
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addOrder.service_content" clearable></el-input>
            </el-form-item>
          </el-col><!-- /服务内容 -->
          <el-col :span="12">
            <el-form-item :label="$t('站点')" prop="or_id">
              <el-select v-model="addOrder.or_id" @change="getOEid(addOrder.or_id)" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in regionList" :key="item.id" :label="item.rname" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /区域id -->
          <el-col :span="12">
            <el-form-item :label="$t('设备')" prop="oe_id">
              <el-select v-model="addOrder.oe_id" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in eqList" :key="item.id" :label="item.equipment_name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /设备id-->
          <el-col :span="12">
            <el-form-item :label="$t('报单时间')" prop="declaration_time" required
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-date-picker v-model="addOrder.declaration_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                :placeholder="$t('选择日期时间')">
              </el-date-picker>
            </el-form-item>
          </el-col><!-- /报单时间-->
          <el-col :span="12">
            <el-form-item :label="$t('工单接收人')" prop="receive_uid">
              <el-select v-model="addOrder.receive_uid" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in roleList" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /工单接收人-->
          <el-col :span="12">
            <el-form-item :label="$t('工单协助人')" prop="assist_uid">
              <el-select v-model="addOrder.assist_uid" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in roleList" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /工单协助人-->
          <el-col :span="12">
            <el-form-item :label="$t('处理方式')" prop="disposition" required
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addOrder.disposition" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in disList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /处理方式-->
          <el-col :span="12">
            <el-form-item :label="$t('质保类型')" prop="warranty_type" required
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addOrder.warranty_type" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in warrList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /质保类型-->
          <el-col :span="24">
            <el-form-item :label="$t('上传图片')" prop="upload_pictures">
              <el-upload action="http://cdshapi.orangeevcharge.com/webapi/file/uploadImg" :http-request="upLoadImg"
                list-type="picture-card" :file-list="addOrder.upload_pictures">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" :modal="false">
                <img width="100%" :src="dialogImageUrl">
              </el-dialog>
            </el-form-item>
          </el-col><!-- /上传图片-->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addOFM = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="upOrder">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
  </div>
</template>

<script>
import mixin from '../../utils/mixin.js'
export default {
  name: 'WarrMess',
  mixins: [mixin],
  data () {
    return {
      UntreatedData: [],
      STime: [],
      ocID: '',
      sEquipment: '',
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      ocList: [],
      eqList: [],
      serList: [{
        id: 1,
        name: this.$t('维修')
      },
      {
        id: 2,
        name: this.$t('升级')
      },
      {
        id: 3,
        name: this.$t('检测')
      },
      {
        id: 4,
        name: this.$t('培训')
      },
      {
        id: 5,
        name: this.$t('投诉')
      },
      {
        id: 0,
        name: this.$t('其他')
      }
      ],
      dispose: false,
      disposeForm: {
        appkey: '',
        url: '',
        platform_appkey: '',
        platform_url: ''
      },
      isedit: true,
      ispull: false,
      pullForm: {
        customer: ''
      },
      CList: [{
        id: 'customer',
        name: this.$t('客户')
      },
      {
        id: 'region',
        name: this.$t('区域')
      },
      {
        id: 'warning',
        name: this.$t('错误码')
      }],
      RList_transfer: [],
      OEid: [],
      addOFM: false,
      addOrder: {
        id: '',
        oc_id: '',
        oe_id: '',
        service_type: '',
        contact_number: '',
        service_content: '',
        contacts: '',
        or_id: '',
        declaration_time: '',
        receive_uid: '',
        assist_uid: '',
        imageUrl: [],
        disposition: '',
        warranty_type: ''
      },
      dialogVisible: false,
      disabled: false,
      dialogImageUrl: '',
      warrList: [{
        value: '1',
        label: this.$t('保内')
      },
      {
        value: '2',
        label: this.$t('保外')
      },
      {
        value: '0',
        label: this.$t('其他')
      }
      ],
      disList: [{
        value: '1',
        label: this.$t('上门服务')
      },
      {
        value: '2',
        label: this.$t('远程解决')
      },
      {
        value: '3',
        label: this.$t('返厂维修')
      },
      {
        value: '4',
        label: this.$t('换货')
      },
      {
        value: '5',
        label: this.$t('直接处理完成')
      },
      {
        value: '0',
        label: this.$t('其他')
      }
      ],
      roleList: [],
      regionList: [],
      user_role: {}
    }
  },
  beforeMount () {
    this.getUserList()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
    this.getEID()
  },
  beforeUpdate () {
    this.ocList = this.$store.state.ocList
    this.RLid = this.$store.state.RLid
    this.OEid = this.$store.state.ERid
  },
  methods: {
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    },
    // 获取列表
    getUserList (page,report) {
      let params = {
        page: page?page:this.Page.page,
        oc_id: this.ocID,
        equipment_id: this.sEquipment
      }
      // 如果没有选择时间搜索则重定向时间
      if (Array.isArray(this.STime).length>0) {
        params.start_time = this.STime[0]
        params.end_time = this.STime[1]
      } else {
        var time1 = new Date()
        time1.setTime(time1.getTime() - 3600 * 1000 * 24 * 7)
        params.start_time = this.$common.formatTime(time1)
        params.end_time = this.$common.formatTime(new Date())
        this.STime = [params.start_time, params.end_time]
      }
      if (report === 1) {
        params.is_report = report
        this.$common.postExcelFile(params, 'platform/get_warning_info')
        return
      }
      this.$axios.post('platform/get_warning_info', params).then((res) => {
        // console.log(res)
        const Data = res.data
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        } else this.$message.error(Data.message)
      }).catch((error) => console.log(error))
    },
    // 做客户数据ID的三级联动
    getInfoID (ocid) {
      this.regionList = []
      this.RLid.forEach(item => {
        if (item.oc_id === ocid) this.regionList.push(item)
      })
      if (this.addOFM) {
        this.addOrder.or_id = this.regionList[0]
        this.getOEid(this.addOrder.or_id)
      }
    },
    // 生成工单里获取设备ID
    getOEid (orid) {
      // console.log('orid', orid)
      this.eqList = []
      if (typeof (orid) === 'object') { orid = orid.id }
      this.OEid.forEach(item1 => {
        if (item1.or_id === orid) this.eqList.push(item1)
      })
      if (this.addOFM) {
        this.addOrder.oe_id = this.eqList[0]
      }
    },
    // 搜索栏的二级联动
    getEID (ocid) {
      this.equipment = []
      this.OEid.forEach(item => {
        if (item.oc_id === ocid) this.equipment.push(item)
      })
    },
    // 获取配置信息
    getDispose () {
      this.$axios.post('platform/get_config').then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.disposeForm = data.data
          this.dispose = true
        } else this.$message.error(data.message)
      }).catch((err) => { console.log(err) })
    },
    // 修改配置信息
    editDis () {
      this.$axios.post('platform/set_config', {
        appkey: this.disposeForm.appkey,
        platform_appkey: this.disposeForm.platform_appkey,
        platform_url: this.disposeForm.platform_url
      }).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          // this.disposeForm = data.data
          this.$message.success(data.message)
          this.dispose = false
          this.isedit = true
        } else this.$message.error(data.message)
      }).catch((err) => {
        console.log(err)
      })
    },
    // 拉取第三方平台信息
    pullInfo () {
      // 显示加载过程
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      // 发送客户ID || this.pullForm.region || this.pullForm.warning
      this.$axios.post('platform/get_platform_info', {
        type: this.pullForm.customer
      }).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          loading.close()
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
          loading.close()
        }
      }).catch(err => console.log(err))
    },
    /* 打开生成工单弹窗 */
    getAddorder (scope) {
      // console.log(scope)
      this.addOrder.upload_pictures = []
      this.addOFM = true
      this.getRoleAll()
      this.getInfoID(scope.row.oc_id)
      this.getOEid(scope.row.or_id)
      this.addOrder.id = scope.row.id
      this.addOrder.oc_id = scope.row.oc_id
      this.addOrder.or_id = scope.row.or_id
      this.addOrder.oe_id = scope.row.oe_id
    },
    // 查看图片
    handlePictureCardPreview (file) {
      //  console.log(file)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 删除图片
    handleRemove (file) {
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('file/delImg', {
          filename: file.url
        }).then((res) => {
          var data = res.data
          if (data.code === 0) {
            var delEle = ''
            if (this.addOrder.upload_pictures) {
              this.addOrder.upload_pictures.forEach((item, index) => {
                if (item === file) delEle = index
              })
              if (delEle === 0) this.addOrder.upload_pictures.splice(0, 1)
              else this.addOrder.upload_pictures.splice(delEle, delEle)
            }
          } else {
            this.$message.error(data.message)
          }
        }).catch((error) => { this.$message.error(error) })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 上传图片
    upLoadImg (file) {
      var FD = new FormData()
      FD.append('filename', file.file, file.file.name)
      this.$axios.post('file/uploadImg', FD).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          file.file.url = data.data.url
          // 如果是生成工单
          if (this.addOFM) {
            this.addOrder.upload_pictures.push(file.file)
          }
        } else {
          this.$message.warning(data.message)
        }
      }).catch(error => this.$message.error(error))
    },
    // 获取所有角色
    getRoleAll () {
      this.$axios.post('admin/getAll').then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.roleList = data.data
        } else {
          this.$message.warning(data.message)
        }
      }).catch((err) => {
        this.$message.error(err)
      })
    },
    // 上传工单
    upOrder () {
      let params = this.$common.forData(this.addOrder)
      this.$axios.post('work_order/add', params).then((res) => {
        if (res.data.code === 0) {
          this.getUserList()
          this.addOFM = false
          var that = this
          // 清空表单
          this.$common.resetField('addOrder', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => this.$message.error(error))
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  .searchBar {
    margin: 0 0 20px;
  }

  .el-range-editor.el-input__inner {
    width: 100%;
  }

  .el-col {
    margin: 10px 0;
  }

  .el-select::v-deep ,
  ::v-deep .el-date-editor.el-input{
    width: 100%;
  }

  .el-input.is-disabled::v-deep .el-input__inner {
    color: #717171;
  }
</style>
